import state from '@/store/modules/student/state'
import getters from '@/store/modules/student/getters'
import mutations from '@/store/modules/student/mutations'
import actions from '@/store/modules/student/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
