import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {message} from 'ant-design-vue'
import LocalStorageHelper from '@/helper/LocalStorageHelper'
import qs from 'qs'

const tokenName = 'x-auth-token'
let rejectedMessageLock = false
// axios.defaults.timeout = 15000 // 有长连接的情况 默认为0不超时
axios.defaults.withCredentials = true
axios.defaults.paramsSerializer = (params) => qs.stringify(params, {arrayFormat: 'brackets'})
axios.interceptors.request.use((axiosRequestConfig: AxiosRequestConfig) => {
    const xAuthToken = LocalStorageHelper.load(tokenName)
    if (axiosRequestConfig && axiosRequestConfig.headers) {
        axiosRequestConfig.headers[tokenName] = xAuthToken
    }
    return axiosRequestConfig;
})

axios.interceptors.response.use((axiosResponse: AxiosResponse) => {
    if (axiosResponse.headers['x-auth-token']) {
        LocalStorageHelper.save(tokenName, axiosResponse.headers['x-auth-token'])
    }
    return axiosResponse
}, () => {
    if (!rejectedMessageLock) {
        rejectedMessageLock = true
        message.warn('网络异常，请检查网络', 1.5, () => {
            rejectedMessageLock = false
        })
    }
    return Promise.reject('网络异常，请检查网络');
})

const api = axios

export default api