import $api from '@/api'

export default class AreaHelper {
    static area: any[]

    static getAll(): any[] {
        return this.area
    }

    // 初始化课程分类缓存
    static init(callback: any) {
        $api.get('/plugin/area/find_all')
            .then(({data: {result: {lists}}}: any) => {
                this.area = lists
                callback()
            })
    }
}