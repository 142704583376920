import state from '@/store/modules/exam_user/state'
import getters from '@/store/modules/exam_user/getters'
import mutations from '@/store/modules/exam_user/mutations'
import actions from '@/store/modules/exam_user/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
