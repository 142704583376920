import $api from '@/api'

export default class CourseCategoryHelper {
    // 注意与后端CourseCategoryService同步
    static continue2new: any = {
        124: 14, // 二建 主项 建筑工程
        125: 15, // 二建 主项 市政公用工程
        126: 16, // 二建 主项 公路工程
        127: 17, // 二建 主项 水利水电工程
        128: 18, // 二建 主项 机电工程
        129: 19, // 二建 主项 矿业工程
        131: 14, // 二建 增项 建筑工程
        132: 15, // 二建 增项 市政公用工程
        133: 16, // 二建 增项 公路工程
        134: 17, // 二建 增项 水利水电工程
        135: 18, // 二建 增项 机电工程
        136: 19, // 二建 增项 矿业工程
        147: 73, // 八大员 安全员
        157: 51, // 三类人员 A
        158: 52, // 三类人员 B
        159: 53, // 三类人员 C2
        193: 191, // 三类人员 C1
        194: 192, // 三类人员 C3
        165: 108, // 试验检测员 A类
        166: 109, // 试验检测员 B类
        167: 110, // 试验检测员 C类
        168: 111, // 试验检测员 D类
        169: 112, // 试验检测员 E类
        170: 113, // 试验检测员 G类
        171: 114, // 试验检测员 H类
        172: 115, // 试验检测员 K类
        173: 116, // 试验检测员 L类
        174: 117, // 试验检测员 F类
        175: 118, // 试验检测员 I类
        176: 119, // 试验检测员 M类
        177: 120, // 试验检测员 SQ类
        178: 121, // 试验检测员 J类
        180: 80, // 特种工 建筑电工
        181: 81, // 特种工 建筑架子工（附着升降脚手架）
        182: 82, // 特种工 建筑起重司索信号工
        183: 83, // 特种工 建筑架子工（普通脚手架）
        184: 84, // 特种工 建筑起重机械司机（物料提升机）
        185: 85, // 特种工 建筑起重机械司机（塔式起重机）
        186: 86, // 特种工 建筑起重机械司机（施工升降机）
        187: 87, // 特种工 高处作业吊篮安装拆卸工
        188: 88, // 特种工 建筑起重机械安装拆卸工（塔式起重机）
        189: 89, // 特种工 建筑起重机械安装拆卸工（物料提升机）
        190: 90, // 特种工 建筑起重机械安装拆卸工（施工升降机）
        // 196: 195, // 特种工 建筑焊工
    }
    static courseCategories: any[]

    static trackIds(id: number): number[] {
        const result: number[] = []
        const trackCourseCategories = this.trackCourseCategories(id)
        for (const trackCourseCategory of trackCourseCategories) {
            result.push(trackCourseCategory.id)
        }
        return result
    }

    static trackIdsReverse(id: number): number[] {
        return this.trackIds(id).reverse()
    }

    static trackNames(id: number): string[] {
        const result: string[] = []
        const trackCourseCategories = this.trackCourseCategories(id)
        for (const trackCourseCategory of trackCourseCategories) {
            result.push(trackCourseCategory.name)
        }
        return result
    }

    static trackNamesReverse(id: number): string[] {
        return this.trackNames(id).reverse()
    }

    static trackCourseCategories(id: number): any[] {
        let result: any[] = []
        for (const courseCategory of this.courseCategories) {
            if (courseCategory.id === id) {
                result.push({id: courseCategory.id, name: courseCategory.name, path: courseCategory.path,})
                if (courseCategory.parentId !== 0) {
                    result = result.concat(this.trackCourseCategories(courseCategory.parentId))
                }
            }
        }
        return result
    }

    static trackCourseCategoriesReverse(id: number): any[] {
        return this.trackCourseCategories(id).reverse()
    }

    static findByParentId(parentId: number): any[] {
        return this.findByParentIdAndCheckField(parentId, [])
    }

    static findByParentIdAndCheckField(parentId: number, checkFields: string[]): any[] {
        const result: any[] = []
        for (const courseCategory of this.courseCategories) {
            if (courseCategory.parentId == parentId) {
                if (checkFields && 0 < checkFields.length) {
                    let checkFlag = false
                    for (const checkField of checkFields) {
                        if (courseCategory[checkField]) {
                            checkFlag = true
                        }
                    }
                    if (checkFlag) {
                        result.push({id: courseCategory.id, name: courseCategory.name, path: courseCategory.path,})
                    }
                } else {
                    result.push({id: courseCategory.id, name: courseCategory.name, path: courseCategory.path,})
                }
            }
        }
        return result;
    }

    // 初始化课程分类缓存
    static init(callback: any) {
        $api.get('/plugin/course_category/find_all')
            .then(({data: {result: {lists}}}: any) => {
                this.courseCategories = lists
                callback()
            })
    }

    static findById(id: number): any {
        const result: any = {}
        for (const courseCategory of this.courseCategories) {
            if (courseCategory.id == id) {
                return courseCategory
            }
        }
        return result;
    }

    static tree(parentId: number): any[] {
        return this.treeAndCheckField(parentId, [])
    }

    static treeAndCheckField(parentId: number, checkFields: string[]): any[] {
        const result: any[] = [];
        for (const courseCategory of this.courseCategories) {
            let checkFlag = false
            if (checkFields && 0 < checkFields.length) {
                for (const checkField of checkFields) {
                    if (courseCategory[checkField]) {
                        checkFlag = true
                        break;
                    }
                }
            } else {
                checkFlag = true
            }
            if (parentId === courseCategory.parentId && checkFlag) {
                const children = this.treeAndCheckField(courseCategory.id, checkFields)
                result.push({
                    key: courseCategory.id,
                    title: courseCategory.name,
                    children,
                })
            }
        }
        return result
    }

    static isContinue(id: number): boolean {
        const courseCategory = this.findById(id)
        return !!(courseCategory && courseCategory.path && courseCategory.path.match(/^0-122/));
    }
}