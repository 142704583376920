import $router from "@/router";
import LocalStorageHelper from '@/helper/LocalStorageHelper'
import {Modal} from 'ant-design-vue'

const beforeEnter = async (to: any, from: any) => {
    const wxStudentTrainSignUpIncomplete = LocalStorageHelper.load('wx_student_train_sign_up_incomplete')
    if (wxStudentTrainSignUpIncomplete?.schoolCourseId) {
        const schoolCourseUrl = '/wx_home/school_course/find/' + wxStudentTrainSignUpIncomplete?.schoolCourseId
        if (schoolCourseUrl != to.fullPath) {
            Modal.confirm({
                content: '您有未完成的培训报名，是否要继续',
                okText: '继续',
                cancelText: '不再提示',
                onOk: () => {
                    $router.push(schoolCourseUrl)
                },
                onCancel: () => {
                    LocalStorageHelper.save('wx_student_train_sign_up_incomplete', null)
                }
            })
        }
    }
    return true
}

export default [
    {
        path: '/wx_home',
        component: () => import('@/page/wx_home/WxHomeLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/wx_home', component: () => import('@/page/wx_home/index/WxHomeIndexIndex.vue')},
            {
                path: '/wx_home/download_question_blank/find/:id',
                component: () => import('@/page/wx_home/download_question_blank/WxHomeDownloadQuestionBlankFind.vue')
            },
            {
                path: '/wx_home/download_question_blank/lists',
                component: () => import('@/page/wx_home/download_question_blank/WxHomeDownloadQuestionBlankLists.vue')
            },
            {
                path: '/wx_home/download_question_blank/lists/:catId',
                component: () => import('@/page/wx_home/download_question_blank/WxHomeDownloadQuestionBlankLists.vue')
            },
            {path: '/wx_home/help/find/:id', component: () => import('@/page/wx_home/help/WxHomeHelpFind.vue')},
            {path: '/wx_home/help/lists', component: () => import('@/page/wx_home/help/WxHomeHelpLists.vue')},
            {
                path: '/wx_home/help/lists/:catId',
                component: () => () => import('@/page/wx_home/help/WxHomeHelpLists.vue')
            },
            {
                path: '/wx_home/public_notice/find/:id',
                component: import('@/page/wx_home/public_notice/WxHomePublicNoticeFind.vue')
            },
            {
                path: '/wx_home/public_notice/lists',
                component: () => import('@/page/wx_home/public_notice/WxHomePublicNoticeLists.vue')
            },
            {
                path: '/wx_home/public_notice/lists/:catId',
                component: () => import('@/page/wx_home/public_notice/WxHomePublicNoticeLists.vue')
            },
            {path: '/wx_home/school/lists', component: () => import('@/page/wx_home/school/WxHomeSchoolLists.vue')},
            {
                path: '/wx_home/school/lists/:supportType',
                component: () => import('@/page/wx_home/school/WxHomeSchoolLists.vue')
            },
            {
                path: '/wx_home/school_course/find/:id',
                component: () => import('@/page/wx_home/school_course/WxHomeSchoolCourseFind.vue')
            },
            {
                path: '/wx_home/school_course/lists',
                component: () => import('@/page/wx_home/school_course/WxHomeSchoolCourseLists.vue')
            },
            {
                path: '/wx_home/school_course/lists/:catId',
                component: () => import('@/page/wx_home/school_course/WxHomeSchoolCourseLists.vue')
            },
            {
                path: '/wx_home/student/forget_password',
                component: () => import('@/page/wx_home/student/WxHomeStudentForgetPassword.vue')
            },
            {path: '/wx_home/student/login', component: () => import('@/page/wx_home/student/WxHomeStudentLogin.vue')},
            {
                path: '/wx_home/student/register',
                component: () => import('@/page/wx_home/student/WxHomeStudentRegister.vue')
            },
            {
                path: '/wx_home/student_certificate/lists',
                component: () => import('@/page/wx_home/student_certificate/WxHomeStudentCertificateLists.vue')
            },
            {path: '/wx_home/:pathMatch(.*)', component: () => import('@/page/wx_home/NotFond.vue')},
        ],
        beforeEnter,
    },
]