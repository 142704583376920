import {message} from 'ant-design-vue'

import $store from '@/store'
import $router from '@/router'
import $api from '@/api'

const beforeEnter = async (to: any, from: any) => {
    if ($store.getters['enterprise/logged']) {
        return true
    }

    await $api.get('/enterprise/enterprise_user/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('enterprise/logged', result)
        } else {
            message.error(msg)
            $router.push('/')
        }
    })
    return true
}

export default [
    {
        path: '/enterprise',
        component: () => import('@/page/enterprise/EnterpriseLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/enterprise/:pathMatch(.*)', component: () => import('@/page/enterprise/NotFond.vue')},
            {path: '/enterprise', component: () => import('@/page/enterprise/index/EnterpriseIndexIndex.vue')},
            {
                path: '/enterprise/enterprise/edit',
                component: () => import('@/page/enterprise/enterprise/EnterpriseEnterpriseEdit.vue')
            },
            {
                path: '/enterprise/enterprise_exam_sign_up/lists',
                component: () => import('@/page/enterprise/enterprise_exam_sign_up/EnterpriseEnterpriseExamSignUpLists.vue')
            },
            {
                path: '/enterprise/enterprise_group/lists',
                component: () => import('@/page/enterprise/enterprise_group/EnterpriseEnterpriseGroupLists.vue')
            },
            {
                path: '/enterprise/enterprise_quality/lists',
                component: () => import('@/page/enterprise/enterprise_quality/EnterpriseEnterpriseQualityLists.vue')
            },
            {
                path: '/enterprise/enterprise_train_sign_up/lists',
                component: () => import('@/page/enterprise/enterprise_train_sign_up/EnterpriseEnterpriseTrainSignUpLists.vue')
            },
            {
                path: '/enterprise/enterprise_user/lists',
                component: () => import('@/page/enterprise/enterprise_user/EnterpriseEnterpriseUserLists.vue')
            },
            {
                path: '/enterprise/enterprise_user/edit',
                component: () => import('@/page/enterprise/enterprise_user/EnterpriseEnterpriseUserEdit.vue')
            },
            {
                path: '/enterprise/enterprise_user_enable_log/lists',
                component: () => import('@/page/enterprise/enterprise_user_enable_log/EnterpriseEnterpriseUserEnableLogLists.vue')
            },
            {
                path: '/enterprise/exam_plan/index',
                component: () => import('@/page/enterprise/exam_plan/EnterpriseExamPlanIndex.vue')
            },
            {
                path: '/enterprise/exam_sign_up/lists',
                component: () => import('@/page/enterprise/exam_sign_up/EnterpriseExamSignUpLists.vue')
            },
            {
                path: '/enterprise/invoice/lists',
                component: () => import('@/page/enterprise/invoice/EnterpriseInvoiceLists.vue')
            },
            {
                path: '/enterprise/notice/lists',
                component: () => import('@/page/enterprise/notice/EnterpriseNoticeLists.vue')
            },
            {
                path: '/enterprise/student/lists',
                component: () => import('@/page/enterprise/student/EnterpriseStudentLists.vue')
            },
            {
                path: '/enterprise/student_certificate/lists',
                component: () => import('@/page/enterprise/student_certificate/EnterpriseStudentCertificateLists.vue')
            },
            {
                path: '/enterprise/student_course_progress/lists',
                component: () => import('@/page/enterprise/student_course_progress/EnterpriseStudentCourseProgressLists.vue')
            },
            {
                path: '/enterprise/student_course_progress_log/lists',
                component: () => import('@/page/enterprise/student_course_progress_log/EnterpriseStudentCourseProgressLogLists.vue')
            },
            {
                path: '/enterprise/student_exam/lists',
                component: () => import('@/page/enterprise/student_exam/EnterpriseStudentExamLists.vue')
            },
            {
                path: '/enterprise/train_sign_up/lists',
                component: () => import('@/page/enterprise/train_sign_up/EnterpriseTrainSignUpLists.vue')
            },
        ],
        beforeEnter,
    },
]