import {createStore} from 'vuex'
import state from '@/store/state'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import adminModule from '@/store/modules/admin'
import enterpriseModule from '@/store/modules/enterprise'
import examStudentModule from '@/store/modules/exam_student'
import examUserModule from '@/store/modules/exam_user'
import schoolModule from '@/store/modules/school'
import studentModule from '@/store/modules/student'
import superviseModule from '@/store/modules/supervise'


export default createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        admin: adminModule,
        enterprise: enterpriseModule,
        examStudent: examStudentModule,
        examUser: examUserModule,
        school: schoolModule,
        student: studentModule,
        supervise: superviseModule,
    },
})
