import {createRouter, createWebHashHistory} from 'vue-router'
import NotFund from '@/page/NotFond.vue'
import adminRouter from './AdminRouter'
import ApiRouter from './ApiRouter'
import enterpriseRouter from './EnterpriseRouter'
import examRouter from './ExamRouter'
import homeRouter from './HomeRouter'
import schoolRouter from './SchoolRouter'
import studentRouter from './StudentRouter'
import superviseRouter from './SuperviseRouter'
import wxHomeRouter from './WxHomeRouter'
import wxStudentRouter from './WxStudentRouter'

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        ...adminRouter,
        ...ApiRouter,
        ...enterpriseRouter,
        ...examRouter,
        ...homeRouter,
        ...schoolRouter,
        ...studentRouter,
        ...superviseRouter,
        ...wxHomeRouter,
        ...wxStudentRouter,
        {
            path: '/:pathMatch(.*)*',
            component: NotFund,
        },
    ],
});
