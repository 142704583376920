import $store from '@/store'
import $api from '@/api'
import $router from '@/router'

let checkAccess = false
const beforeEnter = async (to: any, from: any) => {
    if (!checkAccess) {
        if ('/admin/zzby' === to.path) {
            checkAccess = true
            $router.push('/admin')
            return true
        } else {
            return false
        }
    }
    $api.get('/admin/admin/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('admin/logged', result)
        }
    })
}

export default [
    {
        path: '/admin',
        component: () => import('@/page/admin/AdminLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/admin/:pathMatch(.*)', component: () => import('@/page/admin/NotFond.vue')},
            {path: '/admin', component: () => import('@/page/admin/index/AdminIndexIndex.vue')},
            {path: '/admin/admin/lists', component: () => import('@/page/admin/admin/AdminAdminLists.vue')},
            {path: '/admin/admin/edit', component: () => import('@/page/admin/admin/AdminAdminEdit.vue')},
            {
                path: '/admin/admin_group/lists',
                component: () => import('@/page/admin/admin_group/AdminAdminGroupLists.vue')
            },
            {path: '/admin/admin_log/lists', component: () => import('@/page/admin/admin_log/AdminAdminLogLists.vue')},
            {path: '/admin/area/lists', component: () => import('@/page/admin/area/AdminAreaLists.vue')},
            {
                path: '/admin/course_category/lists',
                component: () => import('@/page/admin/course_category/AdminCourseCategoryLists.vue')
            },
            {
                path: '/admin/enterprise/lists',
                component: () => import('@/page/admin/enterprise/AdminEnterpriseLists.vue')
            },
            {
                path: '/admin/exam_env_process/lists',
                component: () => import('@/page/admin/exam_env_process/AdminExamEnvProcessLists.vue')
            },
            {
                path: '/admin/payment_boao/lists',
                component: () => import('@/page/admin/payment_boao/AdminPaymentBoaoLists.vue')
            },
            {
                path: '/admin/payment_boao/statistic',
                component: () => import('@/page/admin/payment_boao/AdminPaymentBoaoStatistic.vue')
            },
            {
                path: '/admin/payment_cmb/lists',
                component: () => import('@/page/admin/payment_cmb/AdminPaymentCmbLists.vue')
            },
            {
                path: '/admin/payment_cmb/statistic',
                component: () => import('@/page/admin/payment_cmb/AdminPaymentCmbStatistic.vue')
            },
            {
                path: '/admin/pre_library/lists',
                component: () => import('@/page/admin/pre_library/AdminPreLibraryIndex.vue')
            },
            {path: '/admin/school/lists', component: () => import('@/page/admin/school/AdminSchoolLists.vue')},
            {path: '/admin/setting/lists', component: () => import('@/page/admin/setting/AdminSettingLists.vue')},
            {path: '/admin/software/lists', component: () => import('@/page/admin/software/AdminSoftwareLists.vue')},
            {path: '/admin/student/lists', component: () => import('@/page/admin/student/AdminStudentLists.vue')},
            {
                path: '/admin/upload_file/lists',
                component: () => import('@/page/admin/upload_file/AdminUploadFileLists.vue')
            },
            {
                path: '/admin/weixin_keyword/lists',
                component: () => import('@/page/admin/weixin_keyword/AdminWeixinKeywordLists.vue')
            },
            {
                path: '/admin/weixin_menu/lists',
                component: () => import('@/page/admin/weixin_menu/AdminWeixinMenuLists.vue')
            },
        ],
        beforeEnter,
    },
]