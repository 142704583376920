import {message} from 'ant-design-vue'

import $store from '@/store'
import $router from '@/router'
import $api from '@/api'

const beforeEnter = async (to: any, from: any) => {
    if ($store.getters['student/logged']) {
        return true
    }

    await $api.get('/student/student/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('student/logged', result)
        } else {
            message.error(msg)
            $router.push('/')
        }
    })
    return true
}

export default [
    {
        path: '/student',
        component: () => import('@/page/student/StudentLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/student/:pathMatch(.*)', component: () => import('@/page/student/NotFond.vue')},
            {path: '/student', component: () => import('@/page/student/index/StudentIndexIndex.vue')},
            {
                path: '/student/exam_plan/lists',
                component: () => import('@/page/student/exam_plan/StudentExamPlanLists.vue')
            },
            {
                path: '/student/exam_sign_up/lists',
                component: () => import('@/page/student/exam_sign_up/StudentExamSignUpLists.vue')
            },
            {path: '/student/invoice/lists', component: () => import('@/page/student/invoice/StudentInvoiceLists.vue')},
            {path: '/student/notice/lists', component: () => import('@/page/student/notice/StudentNoticeLists.vue')},
            {
                path: '/student/student_authorization/lists',
                component: () => import('@/page/student/student_authorization/StudentStudentAuthorizationLists.vue')
            },
            {
                path: '/student/student_certificate/lists',
                component: () => import('@/page/student/student_certificate/StudentStudentCertificateLists.vue')
            },
            {
                path: '/student/student_course_progress/lists',
                component: () => import('@/page/student/student_course_progress/StudentStudentCourseProgressLists.vue')
            },
            {
                path: '/student/student_course_progress_log/index/:studentCourseProgressId',
                component: () => import('@/page/student/student_course_progress_log/StudentStudentCourseProgressLogIndex.vue')
            },
            {
                path: '/student/student_evaluate_school/lists',
                component: () => import('@/page/student/student_evaluate_school/StudentStudentEvaluateSchoolLists.vue')
            },
            {
                path: '/student/student_exam/lists',
                component: () => import('@/page/student/student_exam/StudentStudentExamLists.vue')
            },
            {
                path: '/student/student_exercise/save',
                component: () => import('@/page/student/student_exercise/StudentStudentExerciseSave.vue')
            },
            {
                path: '/student/student_exercise_paper/lists',
                component: () => import('@/page/student/student_exercise_paper/StudentStudentExercisePaperLists.vue')
            },
            {
                path: '/student/student_exercise_paper_history_question/lists',
                component: () => import('@/page/student/student_exercise_paper_history_question/StudentStudentExercisePaperHistoryQuestionLists.vue')
            },
            {
                path: '/student/student_exercise_paper_question/lists',
                component: () => import('@/page/student/student_exercise_paper_question/StudentStudentExercisePaperQuestionLists.vue')
            },
            {
                path: '/student/student_idea/lists',
                component: () => import('@/page/student/student_idea/StudentStudentIdeaLists.vue')
            },
            {
                path: '/student/student/profile',
                component: () => import('@/page/student/student/StudentStudentEdit.vue')
            },
            {
                path: '/student/student/profile/:type',
                component: () => import('@/page/student/student/StudentStudentEdit.vue')
            },
            {
                path: '/student/train_sign_up/lists',
                component: () => import('@/page/student/train_sign_up/StudentTrainSignUpLists.vue')
            },
        ],
        beforeEnter,
    },
]