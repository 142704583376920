<template>
  <div
      v-if="100 !== loadingPercent"
      :class="$style.loading"
  >
    <div>
      <a-progress
          :percent="loadingPercent"
          type="circle"
      />
      <div
          :class="$style.loading_txt"
      >
        <a-button
            v-if="0 === loadingPercent"
            type="link"
            @click="$router.go(0)"
        >
          刷新
        </a-button>
        <span
            v-else
        >
          正在加载
        </span>
      </div>
    </div>
  </div>

  <a-config-provider
      v-else
      :locale="locale"
  >
    <router-view></router-view>
  </a-config-provider>
</template>

<script lang="tsx" setup>
import {ref} from 'vue'
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import AreaHelper from '@/helper/AreaHelper'
import CourseCategoryHelper from '@/helper/CourseCategoryHelper'
import EnumHelper from './helper/EnumHelper'

dayjs.locale('zh-cn')
const locale = ref(zh_CN)

const loadingPercent = ref(0)

CourseCategoryHelper.init(() => {
  loadingPercent.value += 33
})
EnumHelper.init(() => {
  loadingPercent.value += 33
})
AreaHelper.init(() => {
  loadingPercent.value += 34
})

</script>

<style>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
</style>

<style lang="less" module scoped>
.loading {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .loading_txt {
    margin: 20px 0;
    text-align: center;
  }
}
</style>
