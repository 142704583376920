import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }

import {ref} from 'vue'
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import AreaHelper from '@/helper/AreaHelper'
import CourseCategoryHelper from '@/helper/CourseCategoryHelper'
import EnumHelper from './helper/EnumHelper'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

dayjs.locale('zh-cn')
const locale = ref(zh_CN)

const loadingPercent = ref(0)

CourseCategoryHelper.init(() => {
  loadingPercent.value += 33
})
EnumHelper.init(() => {
  loadingPercent.value += 33
})
AreaHelper.init(() => {
  loadingPercent.value += 34
})


return (_ctx: any,_cache: any) => {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (100 !== loadingPercent.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.loading)
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_a_progress, {
            percent: loadingPercent.value,
            type: "circle"
          }, null, 8, ["percent"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.loading_txt)
          }, [
            (0 === loadingPercent.value)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(0)))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 刷新 ")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, " 正在加载 "))
          ], 2)
        ])
      ], 2))
    : (_openBlock(), _createBlock(_component_a_config_provider, {
        key: 1,
        locale: locale.value
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"]))
}
}

})