import $store from '@/store'
import $api from '@/api'

const beforeEnter = async (to: any, from: any) => {
    $api.get('/supervise/supervise/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit("supervise/logged", result)
        }
    })
}

export default [
    {
        path: '/supervise',
        component: () => import('@/page/supervise/SuperviseLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/supervise/:pathMatch(.*)', component: () => import('@/page/supervise/NotFond.vue')},
            {path: '/supervise', component: () => import('@/page/supervise/index/SuperviseIndexIndex.vue')},
            {path: '/supervise/area/lists', component: () => import('@/page/supervise/area/SuperviseAreaLists.vue')},
            {
                path: '/supervise/certificate_trace/lists',
                component: () => import('@/page/supervise/certificate_trace/SuperviseCertificateTraceLists.vue')
            },
            {path: '/supervise/cockpit', component: () => import('@/page/supervise/SuperviseCockpit.vue')},
            {
                path: '/supervise/course_category/lists',
                component: () => import('@/page/supervise/course_category/SuperviseCourseCategoryLists.vue')
            },
            {
                path: '/supervise/download_question_blank/lists',
                component: () => import('@/page/supervise/download_question_blank/SuperviseDownloadQuestionBlankLists.vue')
            },
            {
                path: '/supervise/download_question_blank_cat/lists',
                component: () => import('@/page/supervise/download_question_blank_cat/SuperviseDownloadQuestionBlankCatLists.vue')
            },
            {
                path: '/supervise/enterprise/lists',
                component: () => import('@/page/supervise/enterprise/SuperviseEnterpriseLists.vue')
            },
            {
                path: '/supervise/enterprise_enable_log/lists',
                component: () => import('@/page/supervise/enterprise_enable_log/SuperviseEnterpriseEnableLogLists.vue')
            },
            {
                path: '/supervise/enterprise_exam_sign_up/lists',
                component: () => import('@/page/supervise/enterprise_exam_sign_up/SuperviseEnterpriseExamSignUpLists.vue')
            },
            {
                path: '/supervise/enterprise_group/lists',
                component: () => import('@/page/supervise/enterprise_group/SuperviseEnterpriseGroupLists.vue')
            },
            {
                path: '/supervise/enterprise_log/lists',
                component: () => import('@/page/supervise/enterprise_log/SuperviseEnterpriseLogLists.vue')
            },
            {
                path: '/supervise/enterprise_train_sign_up/lists',
                component: () => import('@/page/supervise/enterprise_train_sign_up/SuperviseEnterpriseTrainSignUpLists.vue')
            },
            {
                path: '/supervise/enterprise_user/lists',
                component: () => import('@/page/supervise/enterprise_user/SuperviseEnterpriseUserLists.vue')
            },
            {
                path: '/supervise/enterprise_user_enable_log/lists',
                component: () => import('@/page/supervise/enterprise_user_enable_log/SuperviseEnterpriseUserEnableLogLists.vue')
            },
            {
                path: '/supervise/exam_early_warn/lists',
                component: () => import('@/page/supervise/exam_early_warn/SuperviseExamEarlyWarnLists.vue')
            },
            {
                path: '/supervise/exam_firewall/lists',
                component: () => import('@/page/supervise/exam_firewall/SuperviseExamFirewallLists.vue')
            },
            {
                path: '/supervise/exam_machine/lists',
                component: () => import('@/page/supervise/exam_machine/SuperviseExamMachineLists.vue')
            },
            {
                path: '/supervise/exam_monitor/lists',
                component: () => import('@/page/supervise/exam_monitor/SuperviseExamMonitorLists.vue')
            },
            {
                path: '/supervise/exam_paper/lists',
                component: () => import('@/page/supervise/exam_paper/SuperviseExamPaperLists.vue')
            },
            {
                path: '/supervise/exam_plan/index',
                component: () => import('@/page/supervise/exam_plan/SuperviseExamPlanIndex.vue')
            },
            {
                path: '/supervise/exam_plan_practice_user/lists',
                component: () => import('@/page/supervise/exam_plan_practice_user/SuperviseExamPlanPracticeUserLists.vue')
            },
            {
                path: '/supervise/exam_plan_student/lists',
                component: () => import('@/page/supervise/exam_plan_student/SuperviseExamPlanStudentLists.vue')
            },
            {
                path: '/supervise/exam_plan_student/sl_statistic',
                component: () => import('@/page/supervise/exam_plan_student/SuperviseExamPlanStudentSLStatistic.vue')
            },
            {
                path: '/supervise/exam_plan_student/statistic',
                component: () => import('@/page/supervise/exam_plan_student/SuperviseExamPlanStudentStatistic.vue')
            },
            {
                path: '/supervise/exam_practice_user/lists',
                component: () => import('@/page/supervise/exam_practice_user/SuperviseExamPracticeUserLists.vue')
            },
            {
                path: '/supervise/exam_question/lists',
                component: () => import('@/page/supervise/exam_question/SuperviseExamQuestionLists.vue')
            },
            {
                path: '/supervise/exam_room/lists',
                component: () => import('@/page/supervise/exam_room/SuperviseExamRoomLists.vue')
            },
            {
                path: '/supervise/exam_sign_up/lists',
                component: () => import('@/page/supervise/exam_sign_up/SuperviseExamSignUpLists.vue')
            },
            {
                path: '/supervise/exam_sign_up/statistic',
                component: () => import('@/page/supervise/exam_sign_up/SuperviseExamSignUpStatistic.vue')
            },
            {
                path: '/supervise/exam_user/lists',
                component: () => import('@/page/supervise/exam_user/SuperviseExamUserLists.vue')
            },
            {
                path: '/supervise/exam_user_enable_log/lists',
                component: () => import('@/page/supervise/exam_user_enable_log/SuperviseExamUserEnableLogLists.vue')
            },
            {
                path: '/supervise/exam_user_log/lists',
                component: () => import('@/page/supervise/exam_user_log/SuperviseExamUserLogLists.vue')
            },
            {path: '/supervise/help/lists', component: () => import('@/page/supervise/help/SuperviseHelpLists.vue')},
            {
                path: '/supervise/help_cat/lists',
                component: () => import('@/page/supervise/help_cat/SuperviseHelpCatLists.vue')
            },
            {
                path: '/supervise/invoice/lists',
                component: () => import('@/page/supervise/invoice/SuperviseInvoiceLists.vue')
            },
            {
                path: '/supervise/notice/lists',
                component: () => import('@/page/supervise/notice/SuperviseNoticeLists.vue')
            },
            {
                path: '/supervise/public_notice/lists',
                component: () => import('@/page/supervise/public_notice/SupervisePublicNoticeLists.vue')
            },
            {
                path: '/supervise/public_notice_cat/lists',
                component: () => import('@/page/supervise/public_notice_cat/SupervisePublicNoticeCatLists.vue')
            },
            {
                path: '/supervise/school/lists',
                component: () => import('@/page/supervise/school/SuperviseSchoolLists.vue')
            },
            {
                path: '/supervise/school_course/lists',
                component: () => import('@/page/supervise/school_course/SuperviseSchoolCourseLists.vue')
            },
            {
                path: '/supervise/school_enable_log/lists',
                component: () => import('@/page/supervise/school_enable_log/SuperviseSchoolEnableLogLists.vue')
            },
            {
                path: '/supervise/school_exam_sign_up/lists',
                component: () => import('@/page/supervise/school_exam_sign_up/SuperviseSchoolExamSignUpLists.vue')
            },
            {
                path: '/supervise/school_group/lists',
                component: () => import('@/page/supervise/school_group/SuperviseSchoolGroupLists.vue')
            },
            {
                path: '/supervise/school_log/lists',
                component: () => import('@/page/supervise/school_log/SuperviseSchoolLogLists.vue')
            },
            {
                path: '/supervise/school_train_plan/lists',
                component: () => import('@/page/supervise/school_train_plan/SuperviseSchoolTrainPlanLists.vue')
            },
            {
                path: '/supervise/school_train_sign_up/lists',
                component: () => import('@/page/supervise/school_train_sign_up/SuperviseSchoolTrainSignUpLists.vue')
            },
            {
                path: '/supervise/school_user/lists',
                component: () => import('@/page/supervise/school_user/SuperviseSchoolUserLists.vue')
            },
            {
                path: '/supervise/school_user_enable_log/lists',
                component: () => import('@/page/supervise/school_user_enable_log/SuperviseSchoolUserEnableLogLists.vue')
            },
            {
                path: '/supervise/student/lists',
                component: () => import('@/page/supervise/student/SuperviseStudentLists.vue')
            },
            {
                path: '/supervise/student_authorization/lists',
                component: () => import('@/page/supervise/student_authorization/SuperviseStudentAuthorizationLists.vue')
            },
            {
                path: '/supervise/student_certificate/lists',
                component: () => import('@/page/supervise/student_certificate/SuperviseStudentCertificateLists.vue')
            },
            {
                path: '/supervise/student_continue_certificate/lists',
                component: () => import('@/page/supervise/student_continue_certificate/SuperviseStudentContinueCertificateLists.vue')
            },
            {
                path: '/supervise/student_course_progress/lists',
                component: () => import('@/page/supervise/student_course_progress/SuperviseStudentCourseProgressLists.vue')
            },
            {
                path: '/supervise/student_course_progress/statistic',
                component: () => import('@/page/supervise/student_course_progress/SuperviseStudentCourseProgressStatistic.vue')
            },
            {
                path: '/supervise/student_enable_log/lists',
                component: () => import('@/page/supervise/student_enable_log/SuperviseStudentEnableLogLists.vue')
            },
            {
                path: '/supervise/student_evaluate_school/statistic',
                component: () => import('@/page/supervise/student_evaluate_school/SuperviseStudentEvaluateSchoolStatistic.vue')
            },
            {
                path: '/supervise/student_exam/lists',
                component: () => import('@/page/supervise/student_exam/SuperviseStudentExamLists.vue')
            },
            {
                path: '/supervise/student_exercise/lists',
                component: () => import('@/page/supervise/student_exercise/SuperviseStudentExerciseLists.vue')
            },
            {
                path: '/supervise/student_exercise_paper/lists',
                component: () => import('@/page/supervise/student_exercise_paper/SuperviseStudentExercisePaperLists.vue')
            },
            {
                path: '/supervise/student_exercise_question/lists',
                component: () => import('@/page/supervise/student_exercise_question/SuperviseStudentExerciseQuestionLists.vue')
            },
            {
                path: '/supervise/student_idea/lists',
                component: () => import('@/page/supervise/student_idea/SuperviseStudentIdeaLists.vue')
            },
            {
                path: '/supervise/student_log/lists',
                component: () => import('@/page/supervise/student_log/SuperviseStudentLogLists.vue')
            },
            {
                path: '/supervise/supervise/lists',
                component: () => import('@/page/supervise/supervise/SuperviseSuperviseLists.vue')
            },
            {
                path: '/supervise/supervise/mail_list',
                component: () => import('@/page/supervise/supervise/SuperviseSuperviseMailList.vue')
            },
            {
                path: '/supervise/supervise/edit',
                component: () => import('@/page/supervise/supervise/SuperviseSuperviseEdit.vue')
            },
            {
                path: '/supervise/supervise_group/lists',
                component: () => import('@/page/supervise/supervise_group/SuperviseSuperviseGroupLists.vue')
            },
            {
                path: '/supervise/supervise_log/lists',
                component: () => import('@/page/supervise/supervise_log/SuperviseSuperviseLogLists.vue')
            },
            {
                path: '/supervise/train_sign_up/lists',
                component: () => import('@/page/supervise/train_sign_up/SuperviseTrainSignUpLists.vue')
            },
            {
                path: '/supervise/wx_message_send/lists',
                component: () => import('@/page/supervise/wx_message_send/SuperviseWxMessageSendLists.vue')
            },
            {
                path: '/supervise/school_assess_plan/lists',
                component: () => import('@/page/supervise/school_assess_plan/SchoolAssessPlanLisits.vue')
            },
            {
                path: '/supervise/go',
                component: () => import('@/page/supervise/sys/ExamMonitor.vue')
            },
            {
                path: '/supervise/fild',
                component: () => import('@/page/supervise/sys/SuperviseSysFild.vue')
            },
            {
                path: '/supervise/approval',
                component: () => import('@/page/supervise/sys/SuperviseSysApproval.vue')
            },
            {
                path: '/supervise/mail',
                component: () => import('@/page/supervise/sys/SuperviseSysMail.vue')
            },
        ],
        beforeEnter,
    },
]