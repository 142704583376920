export default [
    {
        path: '/',
        component: () => import('@/page/home/HomeLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/', component: () => import('@/page/home/index/HomeIndexIndex.vue')},
            {
                path: '/home/download_question_blank/find/:id',
                component: () => import('@/page/home/download_question_blank/HomeDownloadQuestionBlankFind.vue')
            },
            {
                path: '/home/download_question_blank/lists',
                component: () => import('@/page/home/download_question_blank/HomeDownloadQuestionBlankLists.vue')
            },
            {
                path: '/home/download_question_blank/lists/:catId',
                component: () => import('@/page/home/download_question_blank/HomeDownloadQuestionBlankLists.vue')
            },
            {
                path: '/home/enterprise/forget_password',
                component: () => import('@/page/home/enterprise/HomeEnterpriseForgetPassword.vue')
            },
            {
                path: '/home/enterprise/register',
                component: () => import('@/page/home/enterprise/HomeEnterpriseRegister.vue')
            },
            {path: '/home/help/find/:id', component: () => import('@/page/home/help/HomeHelpFind.vue')},
            {path: '/home/help/lists', component: () => import('@/page/home/help/HomeHelpLists.vue')},
            {path: '/home/help/lists/:catId', component: () => import('@/page/home/help/HomeHelpLists.vue')},
            {
                path: '/home/public_notice/find/:id',
                component: () => import('@/page/home/public_notice/HomePublicNoticeFind.vue')
            },
            {
                path: '/home/public_notice/lists',
                component: () => import('@/page/home/public_notice/HomePublicNoticeLists.vue')
            },
            {
                path: '/home/public_notice/lists/:catId',
                component: () => import('@/page/home/public_notice/HomePublicNoticeLists.vue')
            },
            {
                path: '/home/school/forget_password',
                component: () => import('@/page/home/school/HomeSchoolForgetPassword.vue')
            },
            {path: '/home/school/lists', component: () => import('@/page/home/school/HomeSchoolLists.vue')},
            {
                path: '/home/school/lists/:supportType',
                component: () => import('@/page/home/school/HomeSchoolLists.vue')
            },
            {path: '/home/school/register', component: () => import('@/page/home/school/HomeSchoolRegister.vue')},
            {
                path: '/home/school_course/find/:id',
                component: () => import('@/page/home/school_course/HomeSchoolCourseFind.vue')
            },
            {
                path: '/home/school_course/lists',
                component: () => import('@/page/home/school_course/HomeSchoolCourseLists.vue')
            },
            {
                path: '/home/school_course/lists/:catId',
                component: () => import('@/page/home/school_course/HomeSchoolCourseLists.vue')
            },
            {
                path: '/home/student/forget_password',
                component: () => import('@/page/home/student/HomeStudentForgetPassword.vue')
            },
            {path: '/home/student/register', component: () => import('@/page/home/student/HomeStudentRegister.vue')},
            {
                path: '/home/student_certificate/lists',
                component: () => import('@/page/home/student_certificate/HomeStudentCertificateLists.vue')
            },
            {path: '/home/:pathMatch(.*)', component: () => import('@/page/home/NotFond.vue')},
        ],
    },
    {
        path: '/home/student_authorization/save/:studentId/:relationType/:relationId',
        component: () => import('@/page/home/student_authorization/HomeStudentAuthorizationSave.vue'),
        meta: {
            title: '学员实名授权',
        },
    },
    {
        path: '/home/student/autologin/:token?/:scope?',
        component: () => import('@/page/home/student/HomeStudentAutologin.vue'),
        meta: {
            title: '学员自动登录',
        },
    },
]